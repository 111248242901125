import React, { use, useState } from 'react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import { Button, IconButton, Stack } from '@mui/material';
import ClientDataGrid from '../Client/ClientDataGrid';
import NewClientDialog from '../Client/NewClientDialog';
import { SectionHeader, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import { useLayout } from '../UI/LayoutContext';
import OverflowActions from '../UI/OverflowActions';
import { useScope } from '../Scope/useScope';
import AppSurface from '../UI/AppSurface';
import ScoreFilters, { ScoreFiltersValues } from '../Scores/ScoreFilters';
import { ShowArchivedProps } from '../Archive/useShowArchived';
import { ClientInterface } from './ClientInterface';
import { ClientStats } from './ClientCollector';
import NoClientsNotice, { NoClientsNoticeProps } from './NoClientsNotice';
import { GridOverlay } from '@mui/x-data-grid';
import { UNSCOPED_SCOPE } from '../Scope/Scope';

interface ClientListProps {
  scoreFilters: ScoreFiltersValues;
  showArchivedProps: ShowArchivedProps;
  loading: boolean;
  clients: ClientInterface[];
  clientStats: ClientStats | undefined;
  refetch: () => Promise<any>;
}

const ClientList = ({ scoreFilters, showArchivedProps, loading, clients, clientStats, refetch }: ClientListProps) => {
  const [newClientDialogOpen, setNewClientDialogOpen] = useState(false);
  const { scope } = useScope();
  const layout = useLayout();
  const { showArchived, setShowArchived } = showArchivedProps;

  const title = `${scope.prefix} Clients (${clients.length})`;

  const noClientsOverlayProps: NoClientsNoticeProps = {
    workspaceClientCount: clientStats?.allClientsCount ?? 0,
    clientCount: clientStats?.count ?? 0,
    hasActiveFilters: scoreFilters.filtersApplied,
    onResetFilters: scoreFilters.resetFilters,
  };

  return (
    <AppSurface>
      <SectionHeader>
        <SectionHeaderTitle>{title}</SectionHeaderTitle>

        <SectionHeaderActions>
          <Stack direction='row' spacing={1}>
            <ScoreFilters {...scoreFilters} />

            {layout === 'mobile' ? (
              <IconButton onClick={() => setNewClientDialogOpen(true)}>
                <AddCircleRoundedIcon />
              </IconButton>
            ) : (
              <Button startIcon={<AddCircleRoundedIcon />} onClick={() => setNewClientDialogOpen(true)}>
                Add New Client
              </Button>
            )}
            <NewClientDialog open={newClientDialogOpen} onClose={() => setNewClientDialogOpen(false)} />

            <OverflowActions
              actions={[
                {
                  icon: showArchived ? CheckBoxRoundedIcon : CheckBoxOutlineBlankRoundedIcon,
                  label: 'Show Archived Clients',
                  onSelect: () => setShowArchived(!showArchived),
                },
              ]}
            />
          </Stack>
        </SectionHeaderActions>
      </SectionHeader>

      <ClientDataGrid
        clients={clients}
        loading={loading}
        refetch={refetch}
        slots={{
          noRowsOverlay: NoClientsOverlay,
          noResultsOverlay: NoClientsOverlay,
        }}
        slotProps={{
          noRowsOverlay: noClientsOverlayProps,
          noResultsOverlay: noClientsOverlayProps,
        }}
      />
      <NewClientDialog open={newClientDialogOpen} onClose={() => setNewClientDialogOpen(false)} />
    </AppSurface>
  );
};

const NoClientsOverlay = (props: NoClientsNoticeProps) => {
  return (
    <GridOverlay>
      <NoClientsNotice {...props} />
    </GridOverlay>
  );
};

export default ClientList;
