import React from 'react';
import { Outlet } from 'react-router';
import { gql, useQuery } from '@apollo/client';
import { useScope } from '../Scope/useScope';
import { useWorkspace } from '../Workspace/useWorkspace';
import { Category, CATEGORY_SCORE_FIELDS_FRAGMENT, CategoryType } from './Category';
import { useCategory } from './useCategory';
import { findModuleScoreDimensionSettings } from '../Module/ModuleDimensionSettings';
import { findCategoryScoreDimensionSettings } from './CategoryScoreDimensionSettings';
import TertiaryNavigationTabs, { TertiaryNavigationTab, useTertiaryNavigationTabs } from '../Navigation/TertiaryNavigationTabs';
import PageStyle from '../UI/PageStyle';
import { ActionInterface } from '../UI/ActionInterface';
import { useCategoryParentContext } from './CategoryParentContext';
import { Scorable } from '../Scores/Scorable';
import { CLIENT_BREAKDOWN_FRAGMENT } from '../Client/ClientCollector';
import { IconType } from '../UI/Icon';

export const useCategoryDashboardData = (categoryType: CategoryType) => {
  const { workspace } = useWorkspace();
  const { scope } = useScope();
  const { data } = useQuery(CATEGORY_SCORES_QUERY, {
    variables: {
      workspaceId: workspace.id,
      categoryType: categoryType,
      ...scope.variables,
    },
  });
  if (!data) {
    return null;
  }

  const { scorable } = data;
  const category = scorable.categories[0]!;

  return { scorable, category };
};

export const useCategoryDashboardTabs = (category: Category, basePath: string, additionalTabs: TertiaryNavigationTab[] = []) => {
  const { icon } = findCategoryScoreDimensionSettings(category.type);

  const overviewTab = {
    key: 'overview',
    icon: icon,
    label: 'Overview',
    value: 'overview',
    path: '.',
  };

  const moduleTabs = category.modules.map(module => {
    return {
      key: `module-${module.id}`,
      icon: findModuleScoreDimensionSettings(module.type).icon,
      label: module.name,
      value: module,
      path: `modules/${module.id}`,
    };
  });

  const tabs = [overviewTab, ...additionalTabs, ...moduleTabs];

  return { tabs };
};

type CategoryDashboardGeneralProps = {
  scorable: Scorable;
  category: Category;
  stats?: React.ReactNode;
  detailsTitle?: string;
  children?: React.ReactNode;
  slots?: {
    emptyState?: React.ComponentType<any>;
  };
  slotProps?: {
    emptyState?: Record<string, any>;
  };
  actions?: ActionInterface[];
};

export type CategoryDashboardOutletProps = CategoryDashboardGeneralProps & {
  scopePrefix: string;
  icon: IconType;
};

export type CategoryDashboardProps = CategoryDashboardGeneralProps & {
  additionalTabs?: TertiaryNavigationTab[];
};

const CategoryDashboard = ({
  scorable,
  category,
  additionalTabs = [],
  stats,
  detailsTitle,
  children,
  slots,
  slotProps,
  actions = [],
}: CategoryDashboardProps) => {
  const { scope } = useScope();
  const { parentPath } = useCategoryParentContext();
  const scopePrefix = scope.prefix;

  const { categoryComponentSettings } = useCategory(category.type);
  const { icon, path } = categoryComponentSettings;
  const categoryPath = `${parentPath}/${path}`;

  const { tabs } = useCategoryDashboardTabs(category, parentPath, additionalTabs);

  const tertiaryNavigationTabsProps = useTertiaryNavigationTabs(tabs, categoryPath);

  // TODO: move these routes into the main routes area. not sure yet what to do about the dynamic additional tabs routes
  return (
    <PageStyle theme='dark' fullWidth backgroundProps={{ pt: 3 }}>
      <TertiaryNavigationTabs {...tertiaryNavigationTabsProps} />

      <Outlet context={{ scorable, category, scopePrefix, icon, stats, detailsTitle, children, slots, slotProps, actions }} />

      {/*additionalTabs.map(tab =>
          <Route key={tab.key} path={tab.path} element={tab.component} />
        )*/}
    </PageStyle>
  );
};

export default CategoryDashboard;

const CATEGORY_SCORES_QUERY = gql`
  query CategoryScores($workspaceId: ID!, $categoryType: CategoryType, $scope: ScopeInput) {
    scorable: workspace(id: $workspaceId, scope: $scope) {
      id
      memberStats {
        count
      }
      clientStats {
        count
      }
      categories(type: $categoryType) {
        id
        name
        type
        ...CategoryScoreFields
        ...ClientBreakdown
      }
    }
  }
  ${CATEGORY_SCORE_FIELDS_FRAGMENT}
  ${CLIENT_BREAKDOWN_FRAGMENT}
`;
