import { useOutletContext } from 'react-router';
import { DashboardSurface } from '../Dashboard/Dashboard';
import DashboardSummary from '../Dashboard/DashboardSummary';
import DashboardCategoryDetails from '../Dashboard/DashboardCategoryDetails';
import IntelligenceNotice from '../Intelligence/IntelligenceNotice';
import { useIntelligenceActions } from '../Intelligence/IntelligenceActions';
import { findCategoryScoreDimensionSettings } from './CategoryScoreDimensionSettings';
import { CategoryDashboardOutletProps } from './CategoryDashboard';

const CategoryDashboardOverview = () => {
  const { scorable, category, scopePrefix, icon, stats, detailsTitle, children, slots, slotProps } = useOutletContext<CategoryDashboardOutletProps>();

  const { intelligenceActions, collectIntelligenceDialog } = useIntelligenceActions(scorable, category);

  if (!category) {
    return null;
  }

  const { path } = findCategoryScoreDimensionSettings(category.type);

  if (slots?.emptyState) {
    return (
      <DashboardSurface name={path} variant='primary' title={`${scopePrefix}${category.name}`} overflowActions={intelligenceActions}>
        <slots.emptyState {...slotProps?.emptyState} />
      </DashboardSurface>
    );
  }

  return (
    <>
      <IntelligenceNotice scorable={scorable} scoreDimension={category} rounded />
      <DashboardSurface name={path} variant='primary' title={`${scopePrefix}${category.name}`} overflowActions={intelligenceActions}>
        {collectIntelligenceDialog}
        <DashboardSummary scorable={scorable} scorableLabel={category?.name} scoreDimension={category} scopePrefix={scopePrefix} icon={icon} />

        {stats}

        <DashboardCategoryDetails scorable={scorable} category={category} title={detailsTitle} includeScoreCircle={false}>
          {children}
        </DashboardCategoryDetails>
      </DashboardSurface>
    </>
  );
};

export default CategoryDashboardOverview;
