import { gql } from '@apollo/client';
import { ClientInterface } from './ClientInterface';

export interface ClientCollector {
  clients: ClientInterface[];
  clientStats: ClientStats;
}

export interface ClientStats {
  count: number;
  allClientsCount?: number;
  countByScore?: {
    high: number;
    medium: number;
    low: number;
    collecting: number;
  };
}

export const CLIENT_STATS_FRAGMENT = gql`
  fragment ClientStats on ClientBearerInterface {
    clientStats {
      allClientsCount: count(scope: { type: Unscoped })
      count
    }
  }
`;

export const CLIENT_BREAKDOWN_FRAGMENT = gql`
  fragment ClientBreakdown on ClientBearerInterface {
    clientStats {
      allClientsCount: count(scope: { type: Unscoped })
      count
      countByScore {
        high
        medium
        low
        collecting
      }
    }
  }
`;

// TODO should be on ClientBearerInterface
export const LOWEST_SCORE_CLIENTS_FRAGMENT = gql`
  fragment LowestScoreClients on Aspect {
    clients(limit: 5) {
      id
      name
      health
      intelligence
    }
  }
`;
