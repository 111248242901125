import React from 'react';

import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import HiveRoundedIcon from '@mui/icons-material/HiveRounded';
import { Category } from '../Category/Category';
import { ClientCollector } from '../Client/ClientCollector';
import NoClientsNotice, { NoClientsNoticeProps, useNoClientsNotice } from '../Client/NoClientsNotice';
import DashboardCategory, { DashboardCategoryProps } from '../Dashboard/DashboardCategory';
import { Scorable } from '../Scores/Scorable';
import ClientsScoreBreakdown from './ClientsScoreBreakdown';

interface ClientsDashboardCategoryProps extends DashboardCategoryProps {
  scorable: Scorable & ClientCollector;
  category: Category & ClientCollector;
  includeScoreCircle: boolean;
}

const ClientsDashboardCategory = ({ scorable, category, includeScoreCircle }: ClientsDashboardCategoryProps) => {
  const { clientStats } = category;

  const navigationActions = [
    { icon: DashboardRoundedIcon, label: 'View Client Dashboard', path: 'clients' },
    { icon: CorporateFareRoundedIcon, label: 'View Clients', path: 'clients/list' },
    { icon: HiveRoundedIcon, label: 'View Client Projects', path: 'clients/projects' },
  ];

  const actions = [{ label: 'View Clients', path: 'clients/list' }];

  const { EmptyStateComponent, emptyStateProps } = useNoClientsNotice(clientStats);

  return (
    <DashboardCategory
      scorable={scorable}
      category={category}
      count={clientStats?.count}
      navigationActions={navigationActions}
      actions={actions}
      alignActions='center'
      includeScoreCircle={includeScoreCircle}
      slots={{ emptyState: EmptyStateComponent }}
      slotProps={{ emptyState: emptyStateProps }}
    >
      <ClientsScoreBreakdown scoreDimension={category} />
    </DashboardCategory>
  );
};

export default ClientsDashboardCategory;
