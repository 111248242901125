import { Fragment, useState } from 'react';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import ListNotice from '../UI/ListNotice';
import { useScope } from '../Scope/useScope';
import { UNSCOPED_SCOPE } from '../Scope/Scope';
import NewClientDialog from './NewClientDialog';
import { useNavigate } from 'react-router';
import useWorkspace from '../Workspace/useWorkspace';
import { ClientStats } from './ClientCollector';

export const useNoClientsNotice = (clientStats: ClientStats | undefined) => getNoClientsNoticeProps(clientStats);

export const getNoClientsNoticeProps = (clientStats: ClientStats | undefined) => {
  let ClientsEmptyState;
  let emptyStateProps: NoClientsNoticeProps | undefined;
  if (clientStats?.count === 0) {
    ClientsEmptyState = NoClientsNotice;
    emptyStateProps = {
      workspaceClientCount: clientStats.allClientsCount ?? 0,
      clientCount: clientStats.count,
    };
  }
  return {
    EmptyStateComponent: ClientsEmptyState,
    emptyStateProps,
  };
};

export type NoClientsNoticeProps = {
  workspaceClientCount: number;
  clientCount: number;
  hasActiveFilters?: boolean;
  onResetFilters?: () => void;
};

const NoClientsNotice = ({ workspaceClientCount, hasActiveFilters = false, onResetFilters }: NoClientsNoticeProps) => {
  const { workspace } = useWorkspace();
  const { scope, setScope } = useScope();
  const navigate = useNavigate();
  const [newClientDialogOpen, setNewClientDialogOpen] = useState(false);

  let message;
  let actions = [];

  const joinClientAction = {
    label: 'Join a Client',
    onSelect: () => {
      setScope(UNSCOPED_SCOPE);
      navigate(`/workspace/${workspace.id}/clients/list`);
    },
  };

  if (hasActiveFilters) {
    message = (
      <Fragment>
        Sorry, there&apos;s nothing to see here.
        <br />
        Try resetting your filters.
      </Fragment>
    );
    actions.push({ label: 'Reset Filters', onSelect: onResetFilters });
  } else {
    actions.push({ label: 'Add New Client', onSelect: () => setNewClientDialogOpen(true) });

    if (scope.type === 'Team') {
      message = (
        <Fragment>
          It doesn&apos;t look like this team has any clients.
          <br />
          Add a client or join an existing one.
        </Fragment>
      );
      actions.push(joinClientAction);
    } else {
      if (workspaceClientCount === 0) {
        message = (
          <Fragment>
            It doesn&apos;t look like there are any clients.
            <br />
            Add a client to get started!
          </Fragment>
        );
      } else {
        message = (
          <Fragment>
            It doesn&apos;t look like you are a member of any clients.
            <br />
            Add a client or join an existing one.
          </Fragment>
        );
        actions.push(joinClientAction);
      }
    }
  }

  return (
    <>
      <ListNotice icon={BusinessRoundedIcon} title={'No clients to display'} message={message} actions={actions} />
      <NewClientDialog open={newClientDialogOpen} onClose={() => setNewClientDialogOpen(false)} />
    </>
  );
};

export default NoClientsNotice;
