import { useOutletContext } from 'react-router';
import { Category } from '../Category/Category';
import CategoryDashboard, { CategoryDashboardProps, useCategoryDashboardData } from '../Category/CategoryDashboard';
import { getNoClientsNoticeProps } from '../Client/NoClientsNotice';
import ClientsScoreBreakdown from '../Clients/ClientsScoreBreakdown';
import { MembershipDashboardStats, PersonalDashboardStats } from '../Dashboard/DashboardStats';
import { useScope } from '../Scope/useScope';
import { Scorable } from '../Scores/Scorable';
import { TeamInterface } from '../Team/TeamSchema';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';

// interface ClientsCategoryDashboardProps {
//   additionalTabs?: TertiaryNavigationTab[];
// }

const ClientsCategoryDashboard = () => {
  const { additionalTabs } = useOutletContext<CategoryDashboardProps>();
  const { scope } = useScope();

  const data = useCategoryDashboardData('ClientsCategory');

  if (!data) {
    return null;
  }

  const { scorable, category } = data;
  const clientCount = category.clientStats.count;

  const { EmptyStateComponent, emptyStateProps } = getNoClientsNoticeProps(category.clientStats);

  const breakdownTitle = `${scope.prefix} Clients (${clientCount})`;
  const stats = <ClientsDashboardStats scorable={scorable} category={category} />;

  // {layout !== 'mobile' && <Button variant='outlined' component={RouterLink} to={listPath} >View Clients</Button>}

  return (
    <CategoryDashboard
      scorable={scorable}
      category={category}
      additionalTabs={additionalTabs}
      stats={stats}
      detailsTitle={breakdownTitle}
      slots={{ emptyState: EmptyStateComponent }}
      slotProps={{ emptyState: emptyStateProps }}
    >
      <ClientsScoreBreakdown scoreDimension={category} title={breakdownTitle} />
    </CategoryDashboard>
  );
};

interface ClientsDashboardStatsProps {
  scorable: Scorable;
  category: Category;
}

const ClientsDashboardStats = ({ scorable, category }: ClientsDashboardStatsProps) => {
  const { scope } = useScope();

  if (scope.type === 'Personal') {
    return <PersonalDashboardStats scorable={scorable} scoreDimension={category} intelligenceLabel='My Clients Intelligence' />;
  } else if (scope.type === 'Unscoped') {
    const workspace = scorable as WorkspaceInterface;
    return <MembershipDashboardStats scorable={workspace} scoreDimension={category} intelligenceLabel='Clients Intelligence' />;
  } else if (scope.type === 'Team') {
    const team = scorable as TeamInterface;
    return <MembershipDashboardStats scorable={team} scoreDimension={category} intelligenceLabel='Team Clients Intelligence' />;
  } else {
    return null;
  }
};

export default ClientsCategoryDashboard;
